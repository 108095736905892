<template>
  <section>
    <b-row>
      <b-col
          cols="12"
          md="3"
      >
        <b-form-datepicker
            :value="dateStart"
            :close-button="true"
            reset-button
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            placeholder="From"
            class="mr-2"
            @input="(val) => $emit('update:dateStart', val)"
        />
      </b-col>
      <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
      >
        <b-form-datepicker
            placeholder="To"
            reset-button
            :value="dateEnd"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            :min="dateStart"
            @input="(val) => $emit('update:dateEnd', val)"
        />
      </b-col>
      <b-col>
        <search-with-auto-complete-vue
            @selected="(val) => $emit('update:userFilter', val)"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BFormDatepicker,
  BFormInput, BInputGroup, BInputGroupAppend
} from 'bootstrap-vue'

import SearchWithAutoCompleteVue from '@core/components/shared/search/SearchWithAutoComplete.vue';
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BFormDatepicker,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    SearchWithAutoCompleteVue
  },
  props: {
    dateStart: {
      type: [String, null],
      default: null
    },
    dateEnd: {
      type: [String, null],
      default: null
    },
    userFilter: {
      type: [String, null],
      default: null
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
