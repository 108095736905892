<template>
  <div>
    <list-table
        :columns="tableColumns"
        :records="audits"
        :total-records="totalRecords"
        :show-export-button="true"
        :show-filter-by-default="true"
        :show-search="false"
        :use-auto-complete-search="true"
        @export="onExportAuditTrails"
    >
      <template #filters>
        <b-row>
          <b-col class="">
            <LogsFilter
                :date-start.sync="dateStart"
                :date-end.sync="dateEnd"
                :user-filter="user_id"
            />
          </b-col>
        </b-row>
      </template>

      <template #cell(client)="data">
        <b-link :to="{ name: 'admin-user-single', params: { id: getValueFromSource(data, 'item.client._id') } }">
          <b-media variant="primary" vertical-align="center">
            <template #aside>
              <b-avatar
                  size="3rem"
                  :src="getValueFromSource(data, 'item.client.avatar.path')"
                  :variant="`light-${resolveUserRoleVariant(getValueFromSource(data, 'item.client.user_type'))}`"
                  :to="{ name: 'admin-user-single', params: { id: getValueFromSource(data, 'item.client._id') } }"
              />
            </template>

            <div>
              {{ sentenceCase(`${getValueFromSource(data, 'item.client.full_name')}`) }}
            </div>
          </b-media>
        </b-link>
      </template>

      <template #cell(audit_event)="data">
        <b-badge pill :variant="getValueFromSource(data, 'item.audit_event') === 'Created' ? 'light-success' : 'light-warning'"
                 class="px-1"
        >
          {{ getValueFromSource(data, 'item.audit_event') }}
        </b-badge>
      </template>

      <template #cell(payload)="data">
        <b-button id="toggle-btn" class="my-1" size="sm" variant="primary" @click="toggleModal(getValueFromSource(data, 'item.id'))">
          show
        </b-button>

        <!-- basic modal -->
        <b-modal
            :ref="getValueFromSource(data, 'item.id')"
            :title="getValueFromSource(data, 'item.comment')"
            size="lg"
            centered
            ok-only
            ok-title="Ok"
        >
          <pre> {{ getValueFromSource(data, 'item.payload') }}</pre>
        </b-modal>

      </template>
    </list-table>
  </div>
</template>

<script>
import LogsFilter from "@core/components/shared/admin/logs/LogsFilter.vue";
import vSelect from 'vue-select'
import { debounce, get } from "lodash";
import FileDownload from "js-file-download";
import { formatDate } from "@core/utils/utils";
import ListTable from "@core/components/ListTable/ListTable.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BLink, BButton, BModal, BRow, BCol, BTooltip, BAvatar, BMedia, BBadge } from "bootstrap-vue";

const watchHandler = {
  handler(){
    this.debounceFetchAllData()
  },
  immediate: false
}

const watchHandlerRequiresPageReset = {
  handler(){
    this.currentPage = 1;
    this.debounceFetchAllData()
  },
  immediate: false
}

export default {
  name: "CreatedAuditTrails",
  components: {
    LogsFilter,
    ListTable,
    BRow,
    BCol,
    BLink,
    BModal,
    BMedia,
    BBadge,
    BButton,
    BAvatar,
    BTooltip,
    vSelect
  },
  data() {
    return {
      loading: false,
      audits: [],

      logs: [],
      user_id: "",
      dateEnd: null,
      dateStart: null,

      statusFilter: null,
      debounceFetchAllData: null,

      tableColumns: [
        { key: 'client', label: 'User', sortable: false },
        { key: 'audit_event', sortable: false },
        { key: 'route', sortable: false },
        { key: 'payload', sortable: false },
        { key: 'ip_address', sortable: false },
        { key: 'comment', sortable: false },
        { key: 'created', sortable: false, formatter: val => `${formatDate(val, 'dd-MM-yy h:m:s a')}`, label: 'Date', class: 'width-200' },
      ],
    }
  },
  computed: {
    perPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_RECORDS_PER_PAGE`, value)
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[`navigation/currentPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_CURRENT_PAGE_NUMBER`, value)
      }
    },
    totalRecords: {
      get(){
        return this.$store.getters[`navigation/totalRecords`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_TOTAL_RECORDS`, value)
      }
    },
  },
  watch: {
    user_id: watchHandlerRequiresPageReset,
    statusFilter: watchHandler,
    perPage: watchHandlerRequiresPageReset,
    dateStart: watchHandlerRequiresPageReset,
    dateEnd: watchHandlerRequiresPageReset,
    currentPage: watchHandler,
  },
  created() {
    this.debounceFetchAllData = debounce(this.fetchAllData, 500);

    const {
      status,
      user_id,
      from,
      to,
      page = 1,
      limit = this.perPage
    } = this.$route.query;

    this.user_id = user_id;
    this.statusFilter = status;
    this.dateStart = from;
    this.dateEnd = to;
    this.currentPage = +page;
    this.perPage = +limit;
  },
  methods: {
    async fetchAllData() {
      try {
        this.loading = true;


        const query = {
          limit: this.perPage,
          page: this.currentPage,
          from: this.dateStart,
          to: this.dateEnd,
          user_id: this.user_id,
        }
        await this.$router.push({ query });

        const request = await this.useJwt().adminService.fetchAuditTrails(query);
        const { data, pagination } = request.data;

        this.totalRecords = pagination.totalRecords;
        this.perPage = pagination.limit;
        this.audits = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async onExportAuditTrails(){
      try {
        this.loading = true;

        const result = await this.$swal({
          title: 'Confirm Export?',
          text: "This will export audits based on current filters.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }

        const query = {
          from: this.dateStart,
          to: this.dateEnd,
          user_id: this.user_id
        }

        const response = await this.useJwt().adminService.exportAuditTrails(query);
        FileDownload(response.data, "created-audit-trails.csv");
      } catch (error) {
        const error_message = get(error, "response.statusText") || error.message;

        this.$nextTick(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error_message
            },
          });
        })
      } finally {
        this.loading = false;
      }
    },
    toggleModal(id) {
      this.$refs[id].toggle('#toggle-btn')
    }
  }
}
</script>

<style scoped>

</style>
